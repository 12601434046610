import React, { Component } from "react";

// Material ui
import { Grid }
    from '@material-ui/core/';

// Sketch color picker
import { SketchPicker, TwitterPicker } from 'react-color';

class ColorPickerCustom extends Component {


    render() {
        return (
            <Grid item xs={this.props.gridMargin} className="grid__left--align">

                {/* {this.props.showColorPicker && */}
                    <div className="popover">
                        <div className="cover" onClick={this.props.onClick} />

                        <TwitterPicker
                            color={this.props.color}
                            colors={['#D3D3D3', '#000', '#6D340C', '#693499', '#D80B8F', '#469FE3', '#07A34F', '#EACE2B', '#F4891E', '#E4432F']}
                            onChange={this.props.onChange}
                            triangle={"top-left"}

                        />

                    </div>

                    {/* // :

                    // <div className="swatch" onClick={() => this.props.onClick(this.props.showColorPicker)}>

                    //     <div className="color" style={{ 'background': this.props.color }} />
                    // </div> */}


                {/* } */}
            </Grid>

        )

    }
}
export default ColorPickerCustom