import React, { Component } from "react";

// Material ui
import { Button, CircularProgress }
    from '@material-ui/core/';

import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const BlackButton = withStyles({
    root: {
        boxShadow: 'None',
        backgroundColor: grey[900],
        '&:hover': {
            backgroundColor: grey[800],
            boxShadow: 'None',
        },
        color: 'white',

    },
})(Button);

const WhiteCircularProgress = withStyles({
    root: {
      color: '#fff',
    },
  })(CircularProgress);


class CustomButton extends Component {
   
    
    render() {

        return (
            <BlackButton
            className={this.props.buttonClassName}
                fullWidth={this.props.fullWithStatus}
                disabled={!this.props.disabledStatus}
                onClick={()=>this.props.onClick()}
                size={!this.props.buttonSize ? "small" : this.props.buttonSize}
                variant="contained"
            >
                {(this.props.isLoading || this.props.isLoadingCanvas) && <WhiteCircularProgress size={24} className="LoaderButton" />}
                {(this.props.isLoading || this.props.isLoadingCanvas) ? this.props.textLoading : this.props.text }
            </BlackButton>
        )
    }
}
export default CustomButton