import React, { Component } from "react";

// Internal
import './GridListBadge.css'

// Material ui
import {
    Badge,
    Checkbox,
    CircularProgress,
    GridList,
    GridListTile,
    GridListTileBar,
    withStyles

} from '@material-ui/core';

// Material ui colors
import { grey } from '@material-ui/core/colors';

import Scrollbar from 'react-scrollbars-custom';


const BlackBadge = withStyles(theme => ({
    badge: {
        color: "white",
        backgroundColor: '#E4432F'
    },
}))(Badge);

const BlackProgress = withStyles(theme => ({
    root: {
        color: "black",
    },
}))(CircularProgress);

const BlackCheckbox = withStyles({
    root: {
        color: '#E4432F',
        '&$checked': {
            color: '#E4432F',
        },
    },
    checked: {},
})(Checkbox);



class GridListBadge extends Component {

    // Calculate the number of annotations
    caluclateAnnotations(annotationsObject) {

        let numberOfAnnotations = 0

        for (let annotationType in annotationsObject) {

            numberOfAnnotations += annotationsObject[annotationType].length
        }

        return numberOfAnnotations
    }

    render() {
        return (
            <Scrollbar style={{ height: this.props.galleryHeight }}>
                <GridList cols={1} cellHeight={200} spacing={1} className="gridListSize">
                    {this.props.datasetPresignedURLArray.map((inputFile, index) =>
                        <GridListTile
                            className={(index === Number(this.props.dataIndex) && this.props.dataIndex !== "")
                                ? "selectedBorder galleryHover gridImageMargin roundedBorder"
                                : "dot galleryHover nonSelectedBorder gridImageMargin roundedBorder"}

                            key={index}
                            onClick={() => this.props.onClick(index, true)}

                        >
                            <img className={this.props.isLoadingCanvas && (index === Number(this.props.dataIndex) && this.props.dataIndex !== "") ? "gallery-image--loading" : ""} src={inputFile.urlInputFile} alt={inputFile.fileName} />
                            {this.props.isLoadingCanvas && (index === Number(this.props.dataIndex) && this.props.dataIndex !== "") &&

                                <BlackProgress className="gallery-circular--loading" />
                            }


                            <GridListTileBar
                                titlePosition="bottom"
                                subtitle={<span>{inputFile.fileName}</span>}
                                actionIcon={
                                    <BlackBadge
                                        badgeContent={this.caluclateAnnotations(inputFile.annotations)}
                                        // badgeContent="hej"
                                        className="badgeMargin"
                                        color="primary">
                                    </BlackBadge>

                                }
                                actionPosition="right"
                                className="titleBar"
                            />


                        </GridListTile>
                    )}
                </GridList>
            </Scrollbar>
        )
    }
}
export default GridListBadge