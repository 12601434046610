import React, { Component } from 'react';

// Material ui
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,

} from '@material-ui/core';

// Material ui icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Scrollbar from 'react-scrollbars-custom';

class ExpansionPanelCustom extends Component {
    render() {
        return (
            <Scrollbar style={{ height: this.props.galleryHeight }}>
                <ExpansionPanel square expanded={this.props.expanded === this.props.expandedValue} onChange={this.props.onChange(this.props.expandedValue)}>
                    <ExpansionPanelSummary aria-controls="panel1d-content" expandIcon={<ExpandMoreIcon />} id="panel1d-header">
                        <Typography>{this.props.expansionPanelTitle}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            {this.props.expansionPanelBody}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Scrollbar>
        )
    }
}
export default ExpansionPanelCustom