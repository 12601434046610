import React, { Component, Fragment } from "react";

// CSS
import './AnnotatedObjectList.css' 

// Material ui
import {
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";

// Material ui icons
import DeleteIcon from '@material-ui/icons/Delete';
import RoomIcon from '@material-ui/icons/Room';
import StraightenIcon from '@material-ui/icons/Straighten';
class AnnotatedObjectList extends Component {

    render() {
        return (

            <Fragment>
                <Typography component={'span'} className={this.props.typographyClassName} variant="subtitle2">{this.props.headline}</Typography>
                {
                    Object.entries(this.props.annotationListWithObjects).map(([key, value], index) => (
                        <Grid component={'span'} key={index} item xs={12} className="grid__hover--color annotation__grid--display">
                            

                            <Typography component={'span'} variant="body2" gutterBottom>{value.name}
                                <IconButton aria-label="delete" onClick={() => this.props.handleAnnotationObjectDelete(this.props.annotationType, index)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton aria-label="location" onClick={() => this.props.handleAnnotationShapeOverLay([value.coords], this.props.annotationType)} >
                                    <RoomIcon fontSize="small" />
                                </IconButton>
                                <IconButton aria-label="calculate" onClick={() => this.props.handleAnnotationShapeTextOverlay([value.coords], this.props.annotationType, value.region_area)} >
                                    <StraightenIcon fontSize="small" /> 
                                </IconButton>
                            </Typography>
                        </Grid>
                    ))
                }
            </Fragment>
        )
    }

}
export default AnnotatedObjectList