import React, { Component } from "react";

// Material ui
import {
    Fab,
    Tooltip


} from "@material-ui/core";

class ToolTipFab extends Component {

    render() {
        return (
            <Tooltip className={this.props.tooltipClassName} title={this.props.tooltipTitle} placement={this.props.tooltipPlacement}>
                <span>
                    <Fab
                        className={this.props.fabClassName}
                        disabled={this.props.disableFab}
                        color="inherit"
                        onClick={() => this.props.onClick(this.props.dataIndex, this.props.switchStatus)}
                        size={!this.props.fabSize ? "small" : this.props.fabSize}
                    >

                        {this.props.fabIcon}
                    </Fab>
                </span>
            </Tooltip>
        )
    }

}

export default ToolTipFab