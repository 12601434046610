import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import './App.css';

// Internal
import Routes from "./Routes";

// Material ui
import { AppBar, Button, Grid, Toolbar } from '@material-ui/core/';


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logoHeight: 45,

    }
  }
  render() {

    const childProps = {
    };

    return (
      <Fragment>
        <div className="menuSettings">
          <AppBar position="fixed" className="menuStyling">
            <Toolbar>
            <a href="https://nuclai.co/">
                <img alt="Nuclai logo" src={require('./images/logo.png')} height={this.state.logoHeight} className="logoMargins" />
              </a>
              <span className="textGrow"></span>
              <Button
                className="showOnDesktop"
                color="inherit"
                href="https://nuclai.co/"
                target="_blank"
                variant="outlined"
              >
                Get an early invitation
            </Button>
            </Toolbar>
          </AppBar>
        </div>

        {this.state.isLoading
          ?
          // If loading is true
          <Fragment>
            <Grid
              container
              justify="center"
              className="loadingLogoSegment"
            >
              <img alt="hay_logo" src={require('./images/logo.png')} height="50" />

            </Grid>
            <Grid
              container
              justify="center"
            >
              <img alt="hay_loader" src={require('./images/loader_gif.gif')} height="150" />

            </Grid>
          </Fragment>

          :
          // If loading is false
          <Routes childProps={childProps} />

        }
      </Fragment>

    )

  }

}

export default withRouter(App)
