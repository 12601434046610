import React, { Component } from "react";

// Material ui
import Grid from '@material-ui/core/Grid';


class LoadingStatus extends Component {
    render() {
        return (
            <Grid
                style= {{ 'marginTop': this.props.marginTop + 'px'}}
                className={this.props.className}
                container
                justify="center"
            >
                <img alt={this.props.loaderImageAltText} src={this.props.loaderImage} height={this.props.loaderImageHeight} />

            </Grid>
        )
    }

}
export default LoadingStatus