import React, { Component, Fragment } from 'react';

// CSS
import './AnnotationStudio.css'

// Components
import AnnotatedObjectList from "./AnnotatedObjectList";
import ExpansionPanelCustom from "./ExpansionPanelCustom";
import GridListBadge from "./GridListBadge";
import LoadingStatus from "../components/LoadingStatus";

// Material ui
import {
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";



class AnnotationStudio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            annotationCircleRadius: 5, // The polygon dot radius
            annotationAddDotCircleRadius: 8, // The dot for the preview of a new dot
            availableAnnotationTypes: ['objectDetection', 'segmentation'],
            inverseOriginTransform: new DOMMatrix(),
            originTransform: new DOMMatrix(),
            scaleFactor: 1,
            selectedShapeAnnotationType: "",
            zoomOffsetX: 0,
            zoomOffsetY: 0,


        }

        this.canvasRef = React.createRef(); // The canvas ref


    }

    componentDidMount() {
        // Create the canvas
        const canvas = this.canvasRef.current
        const ctx = canvas.getContext("2d")

        // Create the default cursor
        ctx.canvas.style.cursor = 'default'

        let bgImg = new Image();

        bgImg.onload = () => {
            ctx.drawImage(bgImg, 0, 0, ctx.canvas.width, ctx.canvas.height)

            this.redrawShapes({
                bgImg: bgImg,
                ctx: ctx,
                scaleFactor: undefined,
                newDataIndex: undefined,
                zoomOffsetX: undefined,
                zoomOffsetY: undefined

            })
        }
        bgImg.src = this.props.datasetPresignedURLArray[this.props.dataIndex].urlInputFile

        this.setState({
            ctx: ctx,
            canvas: canvas,
            childIndex: this.props.dataIndex,
            bgImg: bgImg,
            currentZoomScale: 1,
        })
    }

    componentDidUpdate(prevProps) {

        // Check if the parent data index is changed
        if (prevProps.dataIndex !== this.props.dataIndex) {

            // Redraw the canvas
            this.handleFileSelection(this.props.dataIndex, false)
        }

        // Check if the canvas is done loading when fetching annotations
        if (prevProps.isLoadingCanvas !== this.props.isLoadingCanvas) {

            // Redraw the canvas
            // this.handleFileSelection(this.props.dataIndex, false)

            // Get the canvas info
            const canvas = this.canvasRef.current;
            const ctx = canvas.getContext("2d");

            // Redraw the canvas
            this.redrawShapes({
                bgImg: undefined,
                ctx: ctx,
                scaleFactor: undefined,
                newDataIndex: undefined,
                zoomOffsetX: undefined,
                zoomOffsetY: undefined
            })
        }

        // Check if the parent zoom is changed
        if (this.props.parentScaleFactor === 'center') {

            // Create the canvas
            const canvas = this.canvasRef.current
            const ctx = canvas.getContext("2d")

            // Redraw cavas with scale factor 1
            this.redrawShapes({
                bgImg: undefined,
                ctx: ctx,
                scaleFactor: 1,
                newDataIndex: undefined,
                zoomOffsetX: 0,
                zoomOffsetY: 0

            })

            // Send new scale factor to the parent
            this.props.updateParentState(undefined, undefined, 1)

            // Save the state
            this.setState({ scaleFactor: 1, zoomOffsetX: 0, zoomOffsetY: 0 });
        }

        // Check if color changed
        if (prevProps.shapeBorderColor !== this.props.shapeBorderColor) {
            // Get the canvas info
            const canvas = this.canvasRef.current;
            const ctx = canvas.getContext("2d");

            // Redraw the canvas
            this.redrawShapes({
                bgImg: undefined,
                ctx: ctx,
                scaleFactor: undefined,
                newDataIndex: undefined,
                zoomOffsetX: undefined,
                zoomOffsetY: undefined
            })
        }
    }

    // Handle the addition of new annotation objects
    addAnnotationObject = (annotationObjectName, numberAnnotations, values) => {

        // Copy the state
        let newState = Object.assign({}, this.props)
        newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationObjectName].push({ name: "obj" + numberAnnotations, coords: values, selected: false })

        // Declare the annotation as started
        newState.annotationInProgress = true

        // Save the object id for the current annotation 
        newState.currentAnnotationObjectId = numberAnnotations

        // Save the state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.datasetPresignedURLArray[this.props.dataIndex].annotations[annotationObjectName], annotationObjectName, newState.scaleFactor)

    }

    // Handle the addition of new dots to a polygon
    addDotToShape = (ctx, chosenX, chosenY, coords, annotationType, annoationObjectIndex) => {

        let polygonLineSlope
        let polygonLineIntersect

        // Copy the state
        let newState = Object.assign({}, this.state)

        // Modify the shape and add the first dot to the shape to be able to look for that dot as well
        coords.push(coords[0], coords[1])

        // Check which polygon line the click is on
        for (let i = 0; i < coords.length - 1; i += 2) {

            // Calculate the line slope
            polygonLineSlope = this.calculateLineSlope(coords[i], coords[i + 1], coords[i + 2], coords[i + 3])

            if (isNaN(polygonLineSlope)) {
                polygonLineSlope = 0
            }
            // Calculate the intersect
            polygonLineIntersect = this.calculateLineIntersect(coords[i], coords[i + 1], polygonLineSlope)

            // Check if the chosen point is on this line

            let dotOnThisLineStatus = this.calculateIfDotOnLine(chosenX, chosenY, polygonLineSlope, polygonLineIntersect)

            if (dotOnThisLineStatus) {

                // Add the dot to the coords
                coords.splice(i + 2, 0, chosenX)
                coords.splice(i + 3, 0, chosenY)

                // Splice the last two values of the coords to remove the added dots
                coords.pop()
                coords.pop()

                // If the click is on the line, then add the new point to the shape's array
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][annoationObjectIndex]['coords'] = coords

                // Save the state
                this.setState(newState)

                // Send new state to the parent
                this.props.updateParentState(newState.annotations[annotationType], annotationType, newState.scaleFactor)


                break
            }
        }
    }

    // Handle the addition of a shape overlay
    addShapeOverlay(ctx, coordsArray, status, annotationType) {

        // Clear the canvas
        // this.clearCanvas(ctx, undefined, true)
        this.redrawShapes({
            bgImg: undefined,
            ctx: ctx,
            scaleFactor: undefined,
            newDataIndex: undefined,
            zoomOffsetX: undefined,
            zoomOffsetY: undefined
        })


        // Set the stroke settings
        if (status) {
            this.prepareCanvasForDrawing(ctx, "#f00", null, 4)
        }

        // Check if annotation is object detection
        if (annotationType === 'objectDetection' && coordsArray.length !== 0) {
            for (let coordGroup in coordsArray) {
                ctx.beginPath()

                // Loop over the coords and make an overlay
                ctx.strokeRect(
                    coordsArray[coordGroup][0],
                    coordsArray[coordGroup][1],
                    coordsArray[coordGroup][2],
                    coordsArray[coordGroup][3]
                )
                ctx.closePath()
            }
        }

        // Check if the annotation type is segmentation
        if (annotationType === 'segmentation' && coordsArray.length !== 0) {
            for (let coordGroup in coordsArray) {
                ctx.beginPath()
                // Loop over the coords and make an overlay
                for (let i = 0; i < coordsArray[coordGroup].length - 1; i += 2) {
                    ctx.lineTo(coordsArray[coordGroup][i], coordsArray[coordGroup][i + 1]);
                }
                ctx.closePath()
                ctx.stroke()
            }
        }


    }

    addShapeText = (ctx, coords, text, annotationType) => {

        // Get the x,y values of the shape
        let shapeXYValues = this.getShapeXYValues(coords[0], annotationType)

        // Get the middle point of the shape
        let polygonMiddlePoint = this.calculateRectangleMiddlePoints(
            shapeXYValues.xMin,
            shapeXYValues.xMax,
            shapeXYValues.yMin,
            shapeXYValues.yMax)

        ctx.font = "20px Arial";

        // Check if the text will be to close to a border, if so move the coords a bit
        if (polygonMiddlePoint['x'] < 20) {
            polygonMiddlePoint['x'] += 20
        } else if (polygonMiddlePoint['x'] > 420) {
            polygonMiddlePoint['x'] -= 75
        }
        if (polygonMiddlePoint['y'] < 20) {
            polygonMiddlePoint['y'] += 20
        }

        // // Reset the fill style
        this.prepareCanvasForDrawing(ctx, null, this.props.shapeBorderColor, null)

        ctx.fillText(Math.round(text/10) + ' µm²', polygonMiddlePoint['x'], polygonMiddlePoint['y']);
    }




    // Calculate if dot is on the line
    calculateIfDotOnLine(x, y, slope, intersect) {
        return (5 > Math.abs(Math.abs(y) - Math.abs(x * slope + intersect)))
    }

    // Calculate the slope between two points of a polygon line
    calculateLineSlope(x1, y1, x2, y2) {
        return ((y2 - y1) / (x2 - x1))
    }

    // Calculate the line intersection
    calculateLineIntersect(x1, y1, slope) {
        return (y1 - (slope * x1))
    }

    // Calculate the area of a rectangle
    calculateRectangleArea(w, h) {
        return Math.abs(w * h)
    };

    // Calculate tje area of a polygon
    calculatePolygonArea(coords) {

        // // Check if even or odd length
        // if (Math.abs((coords.length/2) % 2) == 1) {

        //     // If odd, then add the first coord to the array
        coords = coords.concat([coords[0], coords[1]])

        // }
        let area = 0
        for (let i = 0; i < coords.length - 2; i += 2) {
            area += (coords[i] * coords[i + 3]) - (coords[i + 1] * coords[i + 2])
        }

        area /= 2

        return Math.abs(area)

    }

    // Calculate the middle points of a rectangle
    calculateRectangleMiddlePoints(xMin, xMax, yMin, yMax) {

        let centerX = (xMin + xMax) / 2
        let centerY = (yMin + yMax) / 2

        return { x: centerX, y: centerY }
    };


    // Calculate the area of a trangle
    calculateTriangleArea(x1, y1, x2, y2, x3, y3) {
        return Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2.0)
    };

    // Change the cursor
    changeCursor = (status, cursorType) => {

        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Copy the state
        // let newState = Object.assign({}, this.state)
        ctx.canvas.style.cursor = status ? cursorType : 'default'

        // this.setState(newState)
    }

    // Check if the polygon is being closed or not
    checkIfClosePolygon = (currentCursorPositionX, currentCursorPositionY) => {
        // Copy the state
        let newState = Object.assign({}, this.state)


        // Check if the cursor position of the click is close to the polygon starting point
        let cursorCloseToStarterPoint = this.checkIfMouseOnDotArea(
            {
                x: currentCursorPositionX,
                y: currentCursorPositionY
            },
            {
                x: newState.datasetPresignedURLArray[newState.dataIndex].annotations.segmentation[newState.currentAnnotationObjectId]['coords'][0],
                y: newState.datasetPresignedURLArray[newState.dataIndex].annotations.segmentation[newState.currentAnnotationObjectId]['coords'][1],
                radius: newState.annotationCircleRadius
            })

        // If the cursor is close to a closing point, then change the cursor return true
        return cursorCloseToStarterPoint ? true : false
    }

    // Check if cursor in shape outline circle
    checkIfMouseOnDotArea(point, circle) {
        return Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
    }

    // Check if the mouse is within an area
    checkIfMouseInArea = (coords, mouseX, mouseY, annotationType) => {

        let shapesXYValues = this.getShapeXYValues(coords, annotationType)

        // Declare all the points
        let x1 = shapesXYValues['xMax']
        let y1 = shapesXYValues['yMin']
        let x2 = shapesXYValues['xMax']
        let y2 = shapesXYValues['yMax']
        let x3 = shapesXYValues['xMin']
        let y3 = shapesXYValues['yMax']
        let x4 = shapesXYValues['xMin']
        let y4 = shapesXYValues['yMin']

        // Calculate area of rectangle ABCD  
        let A = (this.calculateTriangleArea(x1, y1, x2, y2, x3, y3) + this.calculateTriangleArea(x1, y1, x4, y4, x3, y3))

        // Calculate area of triangle PAB  
        let A1 = this.calculateTriangleArea(mouseX, mouseY, x1, y1, x2, y2)

        //  Calculate area of triangle PBC  
        let A2 = this.calculateTriangleArea(mouseX, mouseY, x2, y2, x3, y3)

        //  Calculate area of triangle PCD  
        let A3 = this.calculateTriangleArea(mouseX, mouseY, x3, y3, x4, y4)

        //  Calculate area of triangle PAD  
        let A4 = this.calculateTriangleArea(mouseX, mouseY, x1, y1, x4, y4);

        //  Check if sum of A1, A2, A3  and A4 is same as A  
        return (A === A1 + A2 + A3 + A4)
    }

    // Handle the closure of a polygon by stopping the annotation progess and changing the cursor to default
    closePolygon = () => {

        // Copy the state
        let newState = Object.assign({}, this.state)

        // Declare the annotation as started
        newState.annotationInProgress = false

        // Change back to the default cursor
        newState.canvas.style.cursor = 'default'

        // Save the state
        this.setState(newState)
    }

    // Create a shape border to test
    createShapeBorderToTest(coords, annotationType) {

        // Initiate shape
        let shapeBorderToTest = new Path2D();

        if (annotationType === 'segmentation') {
            for (var j = 0; j < coords.length - 1; j += 2) {
                shapeBorderToTest.lineTo(coords[j], coords[j + 1]);
            }
            shapeBorderToTest.closePath()
        }
        else if (annotationType === 'objectDetection') {
            shapeBorderToTest.rect(coords[0], coords[1], coords[2], coords[3])
        }


        return shapeBorderToTest
    }

    // Delete dot from shape
    deleteDotFromShape(coords, x, y, index) {

        // Copy the state
        let newState = { ...this.state, ...this.props }

        // Remove the position of the x and y values
        let removeValFromIndex = [x, y]

        for (var i = removeValFromIndex.length - 1; i >= 0; i--)
            try {
                coords.splice(removeValFromIndex[i], 1);
            }
            catch (e) {
                console.log(e)
            }


        // Replace the coords of the new state
        try {
            newState.datasetPresignedURLArray[newState.dataIndex].annotations.segmentation[index]['coords'] = coords

        }
        catch (e) {

            console.log("error: ", e)

        }

        // Save the state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.datasetPresignedURLArray[newState.dataIndex].annotations.segmentation, 'segmentation', newState.scaleFactor)

    }

    // Draw a dot for polygon annotation
    drawDot(ctx, x, y, radius) {

        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.fill();

    }

    // Handle the drawing of the polygon
    drawPolygon = (ctx, newX, newY) => {

        // Use the list of the annotation coords
        let loopList = this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.segmentation[this.state.currentAnnotationObjectId].coords

        // Start the path
        ctx.beginPath();

        // Draw lined between every dot in the polygon
        ctx.moveTo(loopList[loopList.length - 4], loopList[loopList.length - 3]);
        ctx.lineTo(newX, newY);

        // Close the path
        ctx.closePath()

        // Do the stroke
        ctx.stroke()
    }

    // Draw rectangle
    drawRectangle = (ctx, currentCursorPositionX, currentCursorPositionY) => {

        let startingPointX = this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.objectDetection[this.state.currentAnnotationObjectId].coords[0]
        let startingPointY = this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.objectDetection[this.state.currentAnnotationObjectId].coords[1]

        // Clear the canvas of residual rectangles and draw everything back
        this.redrawShapes({
            bgImg: undefined,
            ctx: ctx,
            scaleFactor: undefined,
            newDataIndex: undefined,
            zoomOffsetX: undefined,
            zoomOffsetY: undefined

        })

        // Draw rectangle
        ctx.strokeRect(
            startingPointX,
            startingPointY,
            currentCursorPositionX - startingPointX,
            currentCursorPositionY - startingPointY
        )
    }

    // Get the canvas information
    getCanvasInfo = (event) => {
        // Get the canvas info
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        var rect = canvas.getBoundingClientRect();
        let x = event.clientX - rect.left
        let y = event.clientY - rect.top
        let xx = event.clientX
        let yy = event.clientY
        let canvasOffsetX = canvas.offsetLeft
        let canvasOffsetY = canvas.offsetTop

        return { ctx: ctx, x: x, y: y, rect: rect, xOffset: canvasOffsetX, yOffset: canvasOffsetY, xx: xx, yy: yy, canvas: canvas }
    }

    // Get canvas mouse position
    getCanvasRelativeMousePosition(e, canvas) {
        let pos = this.getElementRelativeMousePosition(e, canvas);
        pos[0] = pos[0] * canvas.width / canvas.clientWidth;
        pos[1] = pos[1] * canvas.height / canvas.clientHeight;
        return pos;
    }

    // Get relative mouse position
    getElementRelativeMousePosition(event, canvas) {
        var rect = canvas.getBoundingClientRect();
        return [parseInt(event.clientX - rect.left), parseInt(event.clientY - rect.top)]
    }

    // Get transform relative mouse position
    getTransformRelativeMousePosition(e, canvas) {
        const pos = this.getCanvasRelativeMousePosition(e, canvas);
        const p = new DOMPoint(...pos);
        const point = this.state.inverseOriginTransform.transformPoint(p);
        return [point.x, point.y];
    }

    getShapeXYValues = (coords, annotationType) => {

        // Declare all the variables
        let xMin
        let xMax
        let yMin
        let yMax

        // Check what kind of annotationt type it is
        if (annotationType === 'segmentation') {

            // Divide the coords into a list for the x values and one for the y values
            let xValues = []
            let yValues = []
            for (var i = 0; i < coords.length - 1; i += 2) {
                xValues.push(coords[i])
                yValues.push(coords[i + 1])
            }
            // Find the smallest anf larget x anf y value
            xMin = Math.min(...xValues)
            xMax = Math.max(...xValues)

            yMin = Math.min(...yValues)
            yMax = Math.max(...yValues)

        }
        else if (annotationType === 'objectDetection') {

            // Get the min and max values of the rectangle

            // The x values
            if (coords[0] + coords[2] > coords[0]) {
                xMin = coords[0]
                xMax = coords[0] + coords[2]
            } else {
                xMin = coords[0] + coords[2]
                xMax = coords[0]
            }

            // The y values
            if (coords[1] + coords[3] > coords[1]) {
                yMin = coords[1]
                yMax = coords[1] + coords[3]
            } else {
                yMin = coords[1] + coords[3]
                yMax = coords[1]
            }


        }

        return {
            x1: xMax,
            y1: yMin,
            x2: xMax,
            y2: yMax,
            x3: xMin,
            y3: yMax,
            x4: xMin,
            y4: yMin,
            xMin: xMin,
            xMax: xMax,
            yMin: yMin,
            yMax: yMax,
        }
    }

    // Handle the deletion of an annotation
    handleAnnotationObjectDelete = (annotationType, objectId) => {

        // Declare ctx
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Copy the state
        let newState = { ...this.props, ...this.state }

        // Splice the annotation object
        newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType].splice(objectId, 1)

        // Redraw the canvas
        this.redrawShapes({
            bgImg: undefined,
            ctx: ctx,
            scaleFactor: undefined,
            newDataIndex: undefined,
            zoomOffsetX: undefined,
            zoomOffsetY: undefined
        })

        // Save the state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.annotations[annotationType], annotationType)


    }

    // Handle the overlay on clicked shape
    handleAnnotationShapeOverLay = (coords, annotationType) => {


        // Declare canvas
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        this.addShapeOverlay(ctx, coords, true, annotationType)

    }

    handleAnnotationShapeTextOverlay = (coords, annotationType, area) => {

        // Declare canvas
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Get shape overlay
        this.addShapeOverlay(ctx, coords, true, annotationType)

        // Get shape text
        this.addShapeText(ctx, coords, area, annotationType)

    }

    // Handle the selection of a new file, take the input index and change the chosen data to that index
    handleFileSelection = (index, callParent) => {

        // Redraw the canvas
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Redraw image
        let bgImg = new Image();

        bgImg.onload = () => {

            this.redrawShapes({
                bgImg: bgImg,
                ctx: ctx,
                scaleFactor: undefined,
                newDataIndex: undefined,
                zoomOffsetX: undefined,
                zoomOffsetY: undefined
            })
        }
        bgImg.src = this.props.datasetPresignedURLArray[index].urlInputFile


        // Update the state
        this.setState({
            bgImg
        })

        if (callParent) {
            // Update parent state
            this.props.updateFileSelection(index, this.props.dataIndex)
        }

    }

    // Handle the change of the panel
    handlePanelChange = panel => (event, newExpanded) => {

        // let newExpanded ? panel : false
        if (newExpanded) {
            this.setState({ expanded: panel });

        } else {

            this.setState({ expanded: false });
        }
    };

    // Initialize annotation when no annotation is in progress
    initializeAnnotation(x, y, ctx, annotationType) {
        // Add pointer to canvas
        ctx.moveTo(x, y);

        // Get the length of current amount of annotations of the annotation type
        let numberAnnotations = this.props.datasetPresignedURLArray[this.props.dataIndex].annotations[annotationType].length

        // Add a new annotation object to the state
        this.addAnnotationObject(annotationType, numberAnnotations, [x, y])
    }

    // Handle the looping over an area
    loopForArea(ctx, currentCursorPositionX, currentCursorPositionY) {
        // Declare variable for breaking parent loop
        let exitParentLoop = false

        // Loop over the available annotation types and explore if mouse is hovering
        for (var availableAnnotationType of this.state.availableAnnotationTypes) {

            // Loop over each annotation object in segmentations
            for (let [index, annotationObject] of this.props.datasetPresignedURLArray[this.props.dataIndex].annotations[availableAnnotationType].entries()) {

                // Create the shape
                let shapePathToTest = this.createShapeBorderToTest(annotationObject['coords'], availableAnnotationType)

                let mouseInAreaStatus = ctx.isPointInPath(shapePathToTest, currentCursorPositionX, currentCursorPositionY)

                // Check if the pointer is within a shape's rectangle
                // let mouseInAreaStatus = this.checkIfMouseInArea(annotationObject['coords'], currentCursorPositionX, currentCursorPositionY, availableAnnotationType)
                // let mouseInAreaStatus = this.checkIfMouseInArea(annotationObject['coords'], currentCursorPositionX, currentCursorPositionY, availableAnnotationType)

                if (mouseInAreaStatus) {
                    exitParentLoop = true
                    return {
                        index: index,
                        coords: annotationObject['coords'],
                        annotationType: availableAnnotationType,
                        area: annotationObject.hasOwnProperty("region_area") ? Math.round(annotationObject['region_area']) : undefined
                    }

                }

            }
            if (exitParentLoop) {
                break
            }
        }

    }

    // Handle the looping over the dot area
    loopForDotArea(currentCursorPositionX, currentCursorPositionY) {
        // Declare variable for breaking parent loop
        let exitParentLoop = false

        // Loop over the available annotation types and explore if mouse is hovering
        for (var availableAnnotationType of this.state.availableAnnotationTypes) {

            // Loop over each annotation object in segmentations
            for (let [index, annotationObject] of this.props.datasetPresignedURLArray[this.props.dataIndex].annotations[availableAnnotationType].entries()) {

                // Declare the variable to use for checking if the mouse is within a circle
                let coordsToCheck = annotationObject['coords']

                // Check if the shape is a rectangle, in that case, transform the coords to polygon fashion instead of (x,y,w,h)
                if (availableAnnotationType === 'objectDetection') {
                    coordsToCheck = this.transformRectangleXYCoords(annotationObject['coords'])
                }

                // Loop over the coords in the shape anf check if the pointer is on either of the dots
                for (let i = 0; i < coordsToCheck.length - 1; i += 2) {
                    // Check if pointer is on a shapes dot
                    let mouseOnDotStatus = this.checkIfMouseOnDotArea(
                        {
                            x: currentCursorPositionX,
                            y: currentCursorPositionY
                        },
                        {
                            x: coordsToCheck[i],
                            y: coordsToCheck[i + 1],
                            radius: this.state.annotationCircleRadius
                        })

                    if (mouseOnDotStatus) {
                        exitParentLoop = true

                        // Check the type of the annotation
                        // If polygon, then return the position of the dot
                        if (availableAnnotationType === 'segmentation') {
                            return {
                                activePoint: { x: i, y: i + 1 },
                                annotationType: availableAnnotationType,
                                coords: annotationObject['coords'],
                                index: index
                            }
                        }

                        // If a rectangle, then return the corner values
                        else if (availableAnnotationType === 'objectDetection') {

                            return {
                                activePoint: { x: coordsToCheck[i], y: coordsToCheck[i + 1] },
                                annotationType: availableAnnotationType,
                                coords: annotationObject['coords'],
                                index: index
                            }
                        }
                    }
                }
            }
            if (exitParentLoop) {
                break
            }
        }
    }

    // Handle the looping for a new dot
    loopForNewDot(ctx, currentCursorPositionX, currentCursorPositionY) {

        // Loop over each annotation object in segmentations
        for (let [index, annotationObject] of this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.segmentation.entries()) {

            // Create border to test
            let shapeBorderToTest = this.createShapeBorderToTest(annotationObject['coords'], "segmentation")
            // ctx.stroke(shapeBorderToTest)

            // Check if the mouse is on the cerated border
            let mouseOnBorderStatus = ctx.isPointInStroke(shapeBorderToTest, currentCursorPositionX, currentCursorPositionY)

            if (mouseOnBorderStatus) {
                return {
                    index: index,
                    coords: annotationObject['coords'],
                    annotationType: 'segmentation'
                }
            }
        }


    }

    // Move the mouse and draw circle
    movePositionDrawCircle(ctx, x, y) {

        ctx.moveTo(x, y)
        ctx.arc(x, y, this.state.annotationCircleRadius, 0, 4 * Math.PI);

    }

    // Handle the on mouse down
    onMouseDown = (event) => {

        // Only start when an annotation type is chosen
        if (!this.props.annotationType) {
            return
        }

        // Get the canvas info
        let { ctx, canvas, x, y } = this.getCanvasInfo(event)

        // Get mouse position
        const pos = this.getTransformRelativeMousePosition(event, canvas);

        // Check if annotation is chosen
        if (this.props.annotationType === 'segmentation' || this.props.annotationType === 'objectDetection') {

            // Check if no annotation started, if so, initiate annotation
            if (!this.state.annotationInProgress) {

                // Initialize annotation
                this.initializeAnnotation(pos[0], pos[1], ctx, this.props.annotationType)

                // Prepare canvas for annotations
                this.prepareCanvasForDrawing(ctx, this.props.shapeBorderColor, 1)

                // Check if segmentation is the chosen annotation type, if so, draw a dot
                if (this.props.annotationType === 'segmentation') {

                    // Redraw the canvas
                    this.redrawShapes({
                        bgImg: undefined,
                        ctx: ctx,
                        scaleFactor: undefined,
                        newDataIndex: undefined,
                        zoomOffsetX: undefined,
                        zoomOffsetY: undefined
                    })

                    // // Reset the fill style
                    this.prepareCanvasForDrawing(ctx, null, this.props.shapeBorderColor, null)

                    // // Draw the first dot
                    this.drawDot(ctx, pos[0], pos[1], this.state.annotationCircleRadius)


                } else if (this.props.annotationType === 'objectDetection') {

                    // Start the drag
                    this.setState({ drag: true })

                    // Move the cursor
                    ctx.moveTo(pos[0], pos[1]);

                }
            }

            // Else, a first annotation click is already perfomed, then draw the next part of the annotation
            else {
                // Check what kind of annotation, if segmentation, 
                if (this.props.annotationType === 'segmentation') {

                    // Check if that polygon is being drawn or is being closed by being clicked on its very first dot
                    let checkIfClose = this.checkIfClosePolygon(pos[0], pos[1])

                    // If the click is closing the polygon 
                    if (checkIfClose) {

                        // Add the coordinates to the polygon
                        this.savePolygonCoords(this.props.annotationType, this.state.currentAnnotationObjectId, "", "", true)

                        // Close the polygon
                        this.closePolygon()

                        // Redraw the canvas
                        this.redrawShapes({
                            bgImg: undefined,
                            ctx: ctx,
                            scaleFactor: undefined,
                            newDataIndex: undefined,
                            zoomOffsetX: undefined,
                            zoomOffsetY: undefined

                        })

                    }
                    // If the polygon is not closing, then add the new line
                    else {

                        // Add the click point to the segmentation object
                        this.savePolygonCoords(this.props.annotationType, this.state.currentAnnotationObjectId, pos[0], pos[1])

                        // ctx.beginPath()
                        ctx.moveTo(pos[0], pos[1])

                        // Draw the dot
                        this.drawDot(ctx, pos[0], pos[1], this.state.annotationCircleRadius)

                        // Draw the line
                        this.drawPolygon(ctx, pos[0], pos[1])
                    }

                }

                // If object detection
                if (this.state.annotationType === 'objectDetection') {

                    // Start the rectangle corner
                    ctx.moveTo(pos[0], pos[1]);

                    // Enable drag
                    this.changeDragStatus(true)

                }
            }

        }
        else if (this.props.annotationType === 'move') {

            // Check if the mouse is in shape area
            let mouseInAreaStatus = this.loopForArea(ctx, x, y)

            if (typeof mouseInAreaStatus !== 'undefined') {

                // Get the x,y values of the shape
                let shapeXYValues = this.getShapeXYValues(mouseInAreaStatus.coords, mouseInAreaStatus.annotationType)

                // Get the middle point of the shape
                let polygonMiddlePoint = this.calculateRectangleMiddlePoints(
                    shapeXYValues.xMin,
                    shapeXYValues.xMax,
                    shapeXYValues.yMin,
                    shapeXYValues.yMax)

                // Save the center point of the shape
                this.setState({
                    activeDrag: true,
                    centerPoint: { x: polygonMiddlePoint['x'], y: polygonMiddlePoint['y'] },
                    selectedShapeAnnotationType: mouseInAreaStatus.annotationType,
                    currentAnnotationObjectId: mouseInAreaStatus.index
                })

            }

        }

        // Check if selected is the annotation mode
        else if (this.props.annotationType === 'select') {

            // Check if the mouse is on a dot
            let mouseOnDotAreaStatus = this.loopForDotArea(pos[0], pos[1])

            if (typeof mouseOnDotAreaStatus !== 'undefined') {

                // Save the active points and the selected shape id to the state
                this.setState({
                    activeDrag: true,
                    activePoint: mouseOnDotAreaStatus.activePoint,
                    firstDragDotChosen: "",
                    selectedShapeAnnotationType: mouseOnDotAreaStatus.annotationType,
                    currentAnnotationObjectId: mouseOnDotAreaStatus.index
                })
            }
        }

        // Check if add is the annotation mode
        else if (this.props.annotationType === 'add') {

            // Check if the mouse is on a shapes border
            let mouseOnBorderStatus = this.loopForNewDot(ctx, x, y)

            if (typeof mouseOnBorderStatus !== 'undefined' && mouseOnBorderStatus.annotationType === 'segmentation') {

                // Add a new dot to the shape
                this.addDotToShape(ctx, pos[0], pos[1], mouseOnBorderStatus.coords, 'segmentation', mouseOnBorderStatus.index)

                // Redraw the lines
                this.addShapeOverlay(ctx, [mouseOnBorderStatus.coords], true, 'segmentation')

            }

        }

        // Check if delete is the annotation mode
        else if (this.props.annotationType === 'delete') {
            // Look for chosen x
            let mouseOnDotStatus = this.loopForDotArea(pos[0], pos[1])

            if (mouseOnDotStatus) {

                // Delete the dot
                this.deleteDotFromShape(
                    mouseOnDotStatus.coords,
                    mouseOnDotStatus.activePoint['x'],
                    mouseOnDotStatus.activePoint['y'],
                    mouseOnDotStatus.index
                )

                // Redraw the lines
                this.addShapeOverlay(ctx, [mouseOnDotStatus.coords], true, 'segmentation')

            }
        }

        // Check if zoom in is the annotation mode
        else if (this.props.annotationType === 'zoomIn') {

            // Call zoom in function
            let scaleFactor = this.zoomCanvas(1)

            // Redraw the canvas
            this.redrawShapes({
                bgImg: undefined,
                ctx: ctx,
                scaleFactor: scaleFactor,
                newDataIndex: undefined,
                zoomOffsetX: pos[0],
                zoomOffsetY: pos[1]

            })

            // Save the state
            this.setState({ scaleFactor: scaleFactor, zoomOffsetX: pos[0], zoomOffsetY: pos[1] });

        }

        // Check if zoom in is the annotation mode
        else if (this.props.annotationType === 'zoomOut') {

            // Call zoom out function
            let scaleFactor = this.zoomCanvas(-1)
            // this.zoomOutCanvas(ctx,x,y)

            // Redraw cavas with new scale factor
            this.redrawShapes({
                bgImg: undefined,
                ctx: ctx,
                scaleFactor: scaleFactor,
                newDataIndex: undefined,
                zoomOffsetX: pos[0],
                zoomOffsetY: pos[1]

            })

            // Save the state
            this.setState({ scaleFactor: scaleFactor, zoomOffsetX: pos[0], zoomOffsetY: pos[1] });


        }

        // Check if center is the annotation mode
        else if (this.props.annotationType === 'center') {

            // Redraw cavas with scale factor 1
            this.redrawShapes({
                bgImg: undefined,
                ctx: ctx,
                scaleFactor: 1,
                newDataIndex: undefined,
                zoomOffsetX: pos[0],
                zoomOffsetY: pos[1]

            })

            // Save the state
            this.setState({ scaleFactor: 1, zoomOffsetX: pos[0], zoomOffsetY: pos[1] });
        }

    }

    // Handle the on mouse move
    onMouseMove = (event) => {

        // Get the canvas info
        let { ctx, x, y, canvas } = this.getCanvasInfo(event)

        // Get the mouse position
        const pos = this.getTransformRelativeMousePosition(event, canvas);


        // Save the current mouse position coordinates in the state
        this.setState({
            currentX: x,
            currentY: y
        })

        // Start with default mouse for the cursor
        // Change cursor
        this.changeCursor(true, 'default')


        // If to close a polygon if the annotation type is segmentation and an annotation is in progress
        if (this.props.annotationType === 'segmentation' && this.state.annotationInProgress) {

            // Check if the cursor is on the starting point
            let cursorChange = this.checkIfClosePolygon(pos[0], pos[1])

            // Change cursor
            this.changeCursor(cursorChange, 'pointer')

        }

        // Check if drag is activated and the annotation type is object detection
        if (this.state.drag && this.props.annotationType === 'objectDetection') {

            // Start to draw a rectangle
            this.drawRectangle(ctx, pos[0], pos[1])

        }

        // If inside area and active drag is true, then update the center position of the shape
        if (this.state.activeDrag && this.props.annotationType === 'move') {

            this.updateCenterPointPositionOfShape(ctx, pos[0], pos[1])

        }

        // Chek if annotation type is move
        if (this.props.annotationType === 'move') {

            // Check if the mouse is in shape area
            let mouseInAreaStatus = this.loopForArea(ctx, x, y)

            // If mouse on shape, then change cursor and show overlay
            if (mouseInAreaStatus) {

                // Change cursor
                this.changeCursor(true, 'all-scroll')

                // Add overlay
                this.addShapeOverlay(ctx, [mouseInAreaStatus.coords], true, mouseInAreaStatus.annotationType)

            } else {

                // Keep default cursor
                this.changeCursor(false)

                // Redraw the canvas
                this.redrawShapes({
                    bgImg: undefined,
                    ctx: ctx,
                    scaleFactor: undefined,
                    newDataIndex: undefined,
                    zoomOffsetX: undefined,
                    zoomOffsetY: undefined
                })
            }
        }

        // Check if active drag is processed and if the tool is select
        if (this.state.activeDrag && this.props.annotationType === 'select') {

            // Check if the mouse is on a dot
            this.updateDotDragPosition(
                ctx,
                this.state.selectedShapeAnnotationType,
                this.state.activePoint['x'],
                pos[0],
                this.state.activePoint['y'],
                pos[1])
        }

        // Check if only annotation type select is chosen but not active drag
        if (!this.state.activeDrag && this.props.annotationType === 'select') {

            // Check if the mouse is on a dot
            let mouseInAreaStatus = this.loopForArea(ctx, x, y)

            // If mouse on shape, then change cursor and show overlay
            if (mouseInAreaStatus) {

                // Change cursor
                this.changeCursor(true, 'pointer')

                // Add overlay
                this.addShapeOverlay(ctx, [mouseInAreaStatus.coords], true, mouseInAreaStatus.annotationType)

                // Show dots
                this.showEditionDots(ctx, mouseInAreaStatus.coords, mouseInAreaStatus.annotationType)


            } else {

                // If mouse not in area, then check if on a dor
                let mouseOnDotAreaStatus = this.loopForDotArea(pos[0], pos[1])

                if (mouseOnDotAreaStatus) {
                    // Change cursor
                    this.changeCursor(true, 'pointer')

                    // Add overlay
                    this.addShapeOverlay(ctx, [mouseOnDotAreaStatus.coords], true, mouseOnDotAreaStatus.annotationType)

                    // Show dots
                    this.showEditionDots(ctx, mouseOnDotAreaStatus.coords, mouseOnDotAreaStatus.annotationType)
                }
                // If not on shape or on dot, then reset the cursor
                else {

                    // Change cursor
                    this.changeCursor(false)

                    // Redraw the canvas
                    this.redrawShapes({
                        bgImg: undefined,
                        ctx: ctx,
                        scaleFactor: undefined,
                        newDataIndex: undefined,
                        zoomOffsetX: undefined,
                        zoomOffsetY: undefined
                    })

                }
            }

        }

        // Check if the annotation type is add
        if (this.props.annotationType === 'add') {

            // Check if the mouse is on the shape
            let mouseInAreaStatus = this.loopForArea(ctx, x, y)

            if (mouseInAreaStatus && mouseInAreaStatus.annotationType === 'segmentation') {

                this.redrawShapes({
                    bgImg: undefined,
                    ctx: ctx,
                    scaleFactor: undefined,
                    newDataIndex: undefined,
                    zoomOffsetX: undefined,
                    zoomOffsetY: undefined
                })

                // If the mouse is in the area, then change the cursor
                this.changeCursor(true, 'pointer')

                // Add overlay
                this.addShapeOverlay(ctx, [mouseInAreaStatus.coords], true, mouseInAreaStatus.annotationType)

                // Show dots
                this.showEditionDots(ctx, mouseInAreaStatus.coords, 'segmentation')

                // Also check if the mouse is on a shapes border
                let mouseOnBorderStatus = this.loopForNewDot(ctx, x, y)

                if (typeof mouseOnBorderStatus !== 'undefined') {

                    // If the mouse is in the area, then change the cursor
                    this.changeCursor(true, 'copy')

                    // Show where the new dot would be
                    this.previewNewDot(ctx, pos[0], pos[1])

                }

            } else {

                // Keep default cursor
                this.changeCursor(false)

                // Redraw the canvas
                this.redrawShapes({
                    bgImg: undefined,
                    ctx: ctx,
                    scaleFactor: undefined,
                    newDataIndex: undefined,
                    zoomOffsetX: undefined,
                    zoomOffsetY: undefined
                })
            }

        }

        // Check if delete is the annotation type
        if (this.props.annotationType === 'delete') {

            // Check if the mouse is on the shape
            let mouseInAreaStatus = this.loopForArea(ctx, x, y)

            if (mouseInAreaStatus && mouseInAreaStatus.annotationType === 'segmentation') {

                // If the mouse is in the area, then change the cursor
                this.changeCursor(true, 'pointer')

                // Add overlay
                this.addShapeOverlay(ctx, [mouseInAreaStatus.coords], true, mouseInAreaStatus.annotationType)

                // Show dots
                this.showEditionDots(ctx, mouseInAreaStatus.coords, 'segmentation')

                // Also check if the mouse is on a dot border
                let mouseOnDotStatus = this.loopForDotArea(pos[0], pos[1])

                if (typeof mouseOnDotStatus !== 'undefined') {

                    // If the mouse is in the area, then change the cursor
                    this.changeCursor(true, 'no-drop')

                }

            } else {

                // Keep default cursor
                this.changeCursor(false)

                // Redraw canvas
                this.redrawShapes({
                    bgImg: undefined,
                    ctx: ctx,
                    scaleFactor: undefined,
                    newDataIndex: undefined,
                    zoomOffsetX: undefined,
                    zoomOffsetY: undefined
                })
            }
        }

        // Check if zoom in is the annotation type
        if (this.props.annotationType === 'zoomIn') {
            // Change cursor
            this.changeCursor(true, 'zoom-in')
        }

        // Check if zoom out is the annotation type
        if (this.props.annotationType === 'zoomOut') {
            // Change cursor
            this.changeCursor(true, 'zoom-out')
        }

        // Check if reset zoom is the annotation type
        if (this.props.annotationType === 'center') {
            // Change cursor
            this.changeCursor(true, 'col-resize')
        }

        // Chek if annotation type is evaluate
        if (this.props.annotationType === 'evaluate') {

            // Check if the mouse is in shape area
            let mouseInAreaStatus = this.loopForArea(ctx, x, y)

            // If mouse on shape, then change cursor and show overlay
            if (mouseInAreaStatus) {

                // Change cursor
                this.changeCursor(true, 'ew-resize')

                // Add overlay
                this.addShapeOverlay(ctx, [mouseInAreaStatus.coords], true, mouseInAreaStatus.annotationType)

                // Add text overlay
                if (typeof mouseInAreaStatus.area !== 'undefined') {
                    this.addShapeText(ctx, [mouseInAreaStatus.coords], mouseInAreaStatus.area, mouseInAreaStatus.annotationType)
                }

            } else {

                // Keep default cursor
                this.changeCursor(false)

                // Redraw the canvas
                this.redrawShapes({
                    bgImg: undefined,
                    ctx: ctx,
                    scaleFactor: undefined,
                    newDataIndex: undefined,
                    zoomOffsetX: undefined,
                    zoomOffsetY: undefined
                })
            }
        }


    }

    // Handle the on mouse up
    onMouseUp = (event) => {

        // Get the canvas info
        let { canvas } = this.getCanvasInfo(event)

        // Get the mouse position
        const pos = this.getTransformRelativeMousePosition(event, canvas);

        // Check if selection is chosen, then set stop drag
        if (this.props.annotationType === 'move' || this.props.annotationType === 'select') {
            // Set to drag if mouse down
            this.setState({ drag: false, activeDrag: false })
        }

        // Check if object detection in process, if so, calculate the area of the shape
        if (this.props.annotationType === 'objectDetection') {

            // Save the end points
            this.saveRectangleCoords('objectDetection', this.state.currentAnnotationObjectId, pos[0], pos[1])

        }


    }


    // Prepare the canavas with stroke and line width
    prepareCanvasForDrawing(ctx, strokeColor, fillColor, lineWidth) {
        if (strokeColor) {
            ctx.strokeStyle = strokeColor
        }

        if (fillColor) {
            ctx.fillStyle = fillColor
        }

        if (lineWidth) {
            ctx.lineWidth = lineWidth
        }


    }

    // Handle the preview of a new dot when sweeping with mouse over border
    previewNewDot(ctx, x, y) {

        ctx.fillStyle = "#f00"
        ctx.moveTo(x, y)
        ctx.arc(x, y, this.state.annotationAddDotCircleRadius, 0, 2 * Math.PI);
        ctx.stroke()

        // Reset the canvas drawing
        this.prepareCanvasForDrawing(ctx, "#f00", "#f00", 4)

    }

    // Redraw existing polygons
    redrawExistingPolygons = (ctx, newDataIndex) => {

        // Prepare the canvas for drawing
        this.prepareCanvasForDrawing(ctx, this.props.shapeBorderColor, null, 1)

        // Draw all the existing lines of the polygons
        for (let object of this.props.datasetPresignedURLArray[typeof newDataIndex === 'undefined' ? this.props.dataIndex : newDataIndex].annotations.segmentation) {
            let transformedObject = object['coords']
            ctx.beginPath();
            for (var i = 0; i < transformedObject.length - 1; i += 2) {
                ctx.lineTo(transformedObject[i], transformedObject[i + 1]);
            }
            ctx.closePath()
            ctx.stroke()
        }

    }

    // Redraw existing rectangles
    redrawExistingRectangles = (ctx, newDataIndex) => {

        // Prepare the canvas for drawing
        this.prepareCanvasForDrawing(ctx, this.props.shapeBorderColor, null, 1)

        // Draw all the existing lines of the rectangles
        for (let object of this.props.datasetPresignedURLArray[typeof newDataIndex === 'undefined' ? this.props.dataIndex : newDataIndex].annotations.objectDetection) {
            ctx.beginPath()
            ctx.lineWidth = 1;
            ctx.strokeRect(
                object['coords'][0],
                object['coords'][1],
                object['coords'][2],
                object['coords'][3]
            )
            ctx.closePath()
            ctx.stroke()
        }
    }

    // Redraw the canvas image
    redrawImage = (ctx, bgImg) => {

        ctx.drawImage((typeof bgImg === 'undefined' ? this.state.bgImg : bgImg), 0, 0, ctx.canvas.width, ctx.canvas.height)

    }

    // Redraw all the shapes and the canvas
    redrawShapes = (args) => {


        args.ctx.clearRect(0, 0, args.ctx.canvas.width, args.ctx.canvas.height);
        args.ctx.save();

        let { originTransform, inverseOriginTransform } = this.setZoomAndOffsetTransform(args.scaleFactor, args.zoomOffsetX, args.zoomOffsetY);


        args.ctx.setTransform(
            originTransform.a,
            originTransform.b,
            originTransform.c,
            originTransform.d,
            originTransform.e,
            originTransform.f);

        // Redraw image
        this.redrawImage(args.ctx, args.bgImage)

        // Redraw existing polygons
        this.redrawExistingPolygons(args.ctx, args.newDataIndex)

        // Redraw extisting rectangles
        this.redrawExistingRectangles(args.ctx, args.newDataIndex)

        this.setState({ originTransform, inverseOriginTransform })

    }

    // Save the polygon coord
    savePolygonCoords = (annotationsObjectType, objectId, x, y, closeStatus) => {

        // Copy the state
        let newState = Object.assign({}, this.state)

        // Check if closing the polygon, if not closing, then add the new coords to the annotation object
        if (!closeStatus) {
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['coords'].push(x)
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['coords'].push(y)
        }

        // If close status is true then close the annotation object and do not add any coords to the annotation objecy
        if (closeStatus) {

            newState.annotationInProgress = false

            // Calculate the area of the shape
            let area = this.calculatePolygonArea(newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['coords'])

            // Save the area to the shape
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['region_area'] = area
        }

        // Save the state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.annotations[annotationsObjectType], annotationsObjectType, newState.scaleFactor)

    }

    // Save the rectangle coords
    saveRectangleCoords = (annotationsObjectType, objectId, fullW, fullH) => {

        // Copy the state
        let newState = Object.assign({}, this.state)

        let endpointW = fullW - newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['coords'][0]
        let endpointH = fullH - newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['coords'][1]

        // Stop the drag
        newState.drag = false

        newState.annotationInProgress = false
        newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['coords'].push(endpointW, endpointH)

        // Calculate the area of the shape
        let area = this.calculateRectangleArea(endpointW, endpointH)

        // Add the area to the shape
        newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationsObjectType][objectId]['region_area'] = area

        // Save the bbox to state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.annotations[annotationsObjectType], annotationsObjectType, newState.scaleFactor)

    }


    // Set the offset transform
    setZoomAndOffsetTransform(scaleFactor, zoomOffsetX, zoomOffsetY) {

        let originTransform = new DOMMatrix();
        originTransform.translateSelf(typeof zoomOffsetX === 'undefined' ? this.state.zoomOffsetX : zoomOffsetX, typeof zoomOffsetY === 'undefined' ? this.state.zoomOffsetY : zoomOffsetY);
        originTransform.scaleSelf(typeof scaleFactor === 'undefined' ? this.state.scaleFactor : scaleFactor, typeof scaleFactor === 'undefined' ? this.state.scaleFactor : scaleFactor);
        originTransform.translateSelf(typeof zoomOffsetX === 'undefined' ? -this.state.zoomOffsetX : -zoomOffsetX, typeof zoomOffsetY === 'undefined' ? -this.state.zoomOffsetY : -zoomOffsetY);

        let inverseOriginTransform = originTransform.inverse();

        return { originTransform, inverseOriginTransform }
    }

    // Handle the showing of editable annotation dots
    showEditionDots = async (ctx, coords, annotationType) => {

        // Prepare drawing prerequisites
        this.prepareCanvasForDrawing(ctx, "#f00", "#f00", 4)

        // Check the annotation type
        if (annotationType === 'segmentation') {
            ctx.beginPath();
            for (var i = 0; i < coords.length - 1; i += 2) {
                this.movePositionDrawCircle(ctx, coords[i], coords[i + 1])
            }
            ctx.closePath()
            ctx.fill()
        } else if (annotationType === 'objectDetection') {

            ctx.beginPath();

            // Move to upper left corner and draw circle
            this.movePositionDrawCircle(ctx, coords[0], coords[1])

            // Move to bottom left corner and draw circle
            this.movePositionDrawCircle(ctx, coords[0], coords[1] + coords[3])

            // Move to bottom right corner and draw circle
            this.movePositionDrawCircle(ctx, coords[0] + coords[2], coords[1] + coords[3])

            // Move to upper right corner and draw circle
            this.movePositionDrawCircle(ctx, coords[0] + coords[2], coords[1])

            // Close path and fill
            ctx.closePath()
            ctx.fill()

        }

    }

    // Transform the (x,y,w,h) of a rectangle to polygon structure
    transformRectangleXYCoords(coords) {

        // Declare max and min values
        let xMin
        let xMax
        let yMin
        let yMax

        // Calcukate max and min
        xMin = Math.min(...[coords[0], coords[0] + coords[2]])
        xMax = Math.max(...[coords[0], coords[0] + coords[2]])

        yMin = Math.min(...[coords[1], coords[1] + coords[3]])
        yMax = Math.max(...[coords[1], coords[1] + coords[3]])

        // Create a list with the corners of the rectangle; left top, left bottom, right bottom, right top
        //  leftTop = xMin, yMin
        //  leftBottom = xMin, yMax
        //  rightBottom = xMax, yMax
        //  rightTop = xMax, yMin
        return [xMin, yMin, xMin, yMax, xMax, yMax, xMax, yMin]

    }

    // Handle the update of the center position
    updateCenterPointPositionOfShape = (ctx, newXPosition, newYPosition) => {

        // Copy the state
        let newState = { ...this.state, ...this.props }

        // Check wheter it is a rectangle or a polygon
        if (this.state.selectedShapeAnnotationType === 'objectDetection') {

            // If the shape is a rectangle then update the x and y of the corrsponding shape 
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[this.state.selectedShapeAnnotationType][this.state.currentAnnotationObjectId]['coords'][0] += (newXPosition - newState.centerPoint.x)
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[this.state.selectedShapeAnnotationType][this.state.currentAnnotationObjectId]['coords'][1] += (newYPosition - newState.centerPoint.y)
        }

        else if (this.state.selectedShapeAnnotationType === 'segmentation') {

            // If the shape is a polygon, then loop over each x and y point and update with the difference
            for (let i = 0; i < newState.datasetPresignedURLArray[newState.dataIndex].annotations[this.state.selectedShapeAnnotationType][this.state.currentAnnotationObjectId]['coords'].length - 1; i += 2) {
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[this.state.selectedShapeAnnotationType][this.state.currentAnnotationObjectId]['coords'][i] += (newXPosition - newState.centerPoint.x)
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[this.state.selectedShapeAnnotationType][this.state.currentAnnotationObjectId]['coords'][i + 1] += (newYPosition - newState.centerPoint.y)
            }
        }
        // Save the new last position as center point
        newState.centerPoint.x = newXPosition
        newState.centerPoint.y = newYPosition

        // Update the state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.annotations[this.state.selectedShapeAnnotationType], this.state.selectedShapeAnnotationType, newState.scaleFactor)

        // Redraw the shapes with red
        this.addShapeOverlay(ctx, [newState.datasetPresignedURLArray[newState.dataIndex].annotations[this.state.selectedShapeAnnotationType][this.state.currentAnnotationObjectId]['coords']], true, this.state.selectedShapeAnnotationType)

    }

    // Handle the update of the dot draw position
    updateDotDragPosition = (ctx, annotationType, chosenDotXId, newXvalue, chosenDotYId, newYvalue) => {

        // Copy the state
        let newState = { ...this.state, ...this.props }

        // Check the annotation type
        if (annotationType === 'segmentation') {
            // Update the position of the dragged dot for a polygon
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][chosenDotXId] = newXvalue
            newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][chosenDotYId] = newYvalue
        }
        else if (annotationType === 'objectDetection') {

            // Check if the first drag dot is chosen, if not, check which corner that is getting dragged, otherwise, use the saved corner
            if (newState.firstDragDotChosen === "") {

                // Find which corner that is chosen
                // Declare max and min values
                let xMin
                let xMax
                let yMin
                let yMax

                let coords = newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords']

                xMin = Math.min(...[coords[0], coords[0] + coords[2]])
                xMax = Math.max(...[coords[0], coords[0] + coords[2]])

                yMin = Math.min(...[coords[1], coords[1] + coords[3]])
                yMax = Math.max(...[coords[1], coords[1] + coords[3]])

                // Map the chosen corner from number to text
                let cornerMappingNumberToText = {
                    [xMin]: "xMin",
                    [xMax]: "xMax",
                    [yMin]: "yMin",
                    [yMax]: "yMax"
                }

                // Declare chosen corner
                let chosenCornerX = cornerMappingNumberToText[chosenDotXId]
                let chosenCornerY = cornerMappingNumberToText[chosenDotYId]

                // Find the opposite of the chosen corner
                let oppositeChosenCornerXText = chosenCornerX === 'xMax' ? 'xMin' : 'xMax'
                let oppositeChosenCornerYText = chosenCornerY === 'yMax' ? 'yMin' : 'yMax'

                // Get the number of the oposite corner
                let oppositeChosenCornerXNumber = Number(Object.keys(cornerMappingNumberToText).filter(function (key) { return cornerMappingNumberToText[key] === oppositeChosenCornerXText })[0])
                let oppositeChosenCornerYNumber = Number(Object.keys(cornerMappingNumberToText).filter(function (key) { return cornerMappingNumberToText[key] === oppositeChosenCornerYText })[0])

                // Save the corner to the state
                newState.firstDragDotChosen = [oppositeChosenCornerXNumber, oppositeChosenCornerYNumber]

                // Rewrite the coordinates of the chosen shape by setting the fixed x,y values as the opposite corner
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][0] = oppositeChosenCornerXNumber
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][1] = oppositeChosenCornerYNumber
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][2] = newXvalue - newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][0]
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][3] = newYvalue - newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][1]

            } else {

                // Rewrite the coordinates of the chosen shape by setting the fixed x,y values as the opposite corner
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][0] = newState.firstDragDotChosen[0]
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][1] = newState.firstDragDotChosen[1]
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][2] = newXvalue - newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][0]
                newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][3] = newYvalue - newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords'][1]

            }

        }



        // Update the state
        this.setState(newState)

        // Send new state to the parent
        this.props.updateParentState(newState.annotations[annotationType], annotationType, newState.scaleFactor)


        // Redraw the shapes with red
        this.addShapeOverlay(ctx, [newState.datasetPresignedURLArray[newState.dataIndex].annotations[annotationType][this.state.currentAnnotationObjectId]['coords']], true, annotationType)

    }

    // Handle the zooming of the canvas
    zoomCanvas = (status) => {

        let scaleFactor;


        if (status < 0) {
            scaleFactor = this.state.scaleFactor / 1.1

        } else {
            scaleFactor = this.state.scaleFactor * 1.1

        }

        if (status < 0 && scaleFactor <= 1.3) {

            // Send new scale factor to the parent
            this.props.updateParentState(undefined, undefined, 1)
            return 1
        }

        // Send new scale factor to the parent
        this.props.updateParentState(undefined, undefined, scaleFactor)

        return scaleFactor

    }




    render() {
        return (
            <Fragment>

                {/* Gallery grid start */}
                <Grid className="demo__grid--backgorund-white desktop__display" item md={2}>
                    <GridListBadge
                        dataIndex={this.props.dataIndex}
                        galleryHeight={this.props.galleryHeight}
                        isLoadingCanvas={this.props.isLoadingCanvas}
                        onClick={this.handleFileSelection}
                        datasetPresignedURLArray={this.props.datasetPresignedURLArray}

                    />
                </Grid>

                {/* Empty grid start */}
                <Grid item md={1} className="desktop__display">
                </Grid>

                {/* Annotation grid desktop */}
                <Grid item xs={12} md={6} className="demo__paper-grid--margin-top">
                    <Paper className="demo__paper--padding ">


                        <Grid container justify="center">

                            {/* Loader when fetching new data */}
                            {this.props.isLoadingCanvas &&
                                <LoadingStatus
                                    className={"loadingGifMargin canvas--loading-gif"}
                                    loaderImage={require("../images/loader_gif.gif")}
                                    loaderImageHeight={150}
                                />
                            }

                            <canvas
                                className={this.props.isLoadingCanvas ? "canvas--loading-opacity" : ""}
                                ref={this.canvasRef}
                                onMouseDown={(e) => this.onMouseDown(e)}
                                onMouseMove={(e) => this.onMouseMove(e)}
                                onMouseUp={(e) => this.onMouseUp(e)}
                                width={this.props.canvasWidth}
                                height={this.props.canvasHeight}
                                style={{
                                    color: this.props.shapeBorderColor,
                                }}
                            />

                        </Grid>
                    </Paper>
                </Grid>


                {/* Empty grid */}
                <Grid item md={1} className="desktop__display">
                </Grid>

                {/* Summary grid */}
                <Grid item md={2} className="desktop__display">
                    <Typography variant="h6"> {this.props.datasetPresignedURLArray[this.props.dataIndex].fileName}</Typography>
                    <ExpansionPanelCustom
                        expanded={this.state.expanded}
                        expandedValue={'panel1'}
                        expansionPanelTitle={"Annotations"}
                        expansionPanelBody={
                            <Fragment>
                                {this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.segmentation.length > 0 &&
                                    <Fragment>
                                        <AnnotatedObjectList
                                            annotationType={'segmentation'}
                                            headline={"Segmentation"}
                                            annotationListWithObjects={this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.segmentation}
                                            handleAnnotationObjectDelete={this.handleAnnotationObjectDelete}
                                            handleAnnotationShapeOverLay={this.handleAnnotationShapeOverLay}
                                            handleAnnotationShapeTextOverlay={this.handleAnnotationShapeTextOverlay}

                                        />
                                    </Fragment>
                                }
                                {this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.objectDetection.length > 0 &&
                                    <Fragment>
                                        <AnnotatedObjectList
                                            annotationType={'objectDetection'}
                                            headline={"Object detection"}
                                            annotationListWithObjects={this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.objectDetection}
                                            handleAnnotationObjectDelete={this.handleAnnotationObjectDelete}
                                            handleAnnotationShapeOverLay={this.handleAnnotationShapeOverLay}
                                            handleAnnotationShapeTextOverlay={this.handleAnnotationShapeTextOverlay}
                                            typographyClassName="annotation__grid--margin"
                                        />
                                    </Fragment>
                                }
                                {/* Write status if no annotations are avilable */}
                                {this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.segmentation.length === 0 && this.props.datasetPresignedURLArray[this.props.dataIndex].annotations.objectDetection.length === 0 &&
                                    <Typography component={'span'} variant="body2">No annotations yet</Typography>
                                }
                            </Fragment>

                        }
                        galleryHeight={this.props.galleryHeight}
                        onChange={this.handlePanelChange}
                    />


                </Grid>




            </Fragment>

        )
    }
}

export default AnnotationStudio 