import React, { Component, Fragment } from "react";

// Components
import AnnotationStudio from '../components/AnnotationStudio'
import ColorPickerCustom from "../components/ColorPickerCustom";
import CustomButton from '../components/CustomButton'
import CustomButtonHttp from '../components/CustomButtonHttp'
import LoadingStatus from "../components/LoadingStatus";
import ToolTipFab from '../components/ToolTipFab'

// CSS
import './Demo.css'

// External
import Hotkeys from 'react-hot-keys';

// Internal
import annotationData1 from '../annotations/new_1.json'
import annotationData2 from '../annotations/new_2.json'
import annotationData3 from '../annotations/3.json'

// Material ui
import {
    AppBar,
    Grid,
    Toolbar,
} from "@material-ui/core";

// Material ui icons 
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CropFreeIcon from '@material-ui/icons/CropFree';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import StraightenIcon from '@material-ui/icons/Straighten';
import TimelineIcon from '@material-ui/icons/Timeline';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

// Material ui lab
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

class Demo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            annotations: {
                "Image1.tiff": annotationData1,
                "Image2.tiff": annotationData2,
                "Image3.tiff": annotationData3
            },
            annotationType: "segmentation", // The annotation type to start with
            keyboardShortCuts: {
                'a': 'add',
                'c': 'center',
                'd': 'delete',
                'e': 'evaluate',
                'm': 'move',
                'p': 'segmentation',
                'r': 'objectDetection',
                's': 'select',
                'z': 'zoomIn',
                '-': 'zoomOut',
            },
            isLoading: true,
            link: "",
            parentScaleFactor: 1,
            shapeBorderColor: '#EACE2B',
            showColorPicker: false,
            transformingAnnotationTypes: ['add', 'delete'] // Types that only works with annotations

        }
    }

    componentDidMount() {

        let datasetPresignedURLArray = []
        let canvasWidth
        let canvasHeight
        let link

        // Check if parameter 
        if (this.props.match.params.id) {

            datasetPresignedURLArray = [
                {
                    fileName: "Image3.tiff",
                    dataId: "8aa6d6d2-688f-11ea-b8af-8a0c633d5c27",
                    urlInputFile: "https://nuclai-images.s3.amazonaws.com/Image3.jpg",
                    annotations: {
                        segmentation: [],
                        objectDetection: []
                    }
                }
            ]

            canvasWidth = 901
            canvasHeight = 481
            link = "https://demo.nuclai.co/nuclai_report_ipsc_cm.pdf"


        }

        else {



            datasetPresignedURLArray = [
                {
                    fileName: "Image1.tiff",
                    dataId: "8aa6d6d2-688f-11ea-b8af-8a0c633d5c27",
                    urlInputFile: "https://nuclai-images.s3.amazonaws.com/Image1.jpg",
                    annotations: {
                        segmentation: [],
                        objectDetection: []
                    }
                },
                {
                    fileName: "Image2.tiff",
                    dataId: "8aa6d8da-688f-11ea-b8af-8a0c633d5c27",
                    urlInputFile: "https://nuclai-images.s3.amazonaws.com/Image2.jpg",
                    annotations: {
                        segmentation: [],
                        objectDetection: []
                    }
                }
            ]

            canvasWidth = 2048
            canvasHeight = 2048
            link = "https://demo.nuclai.co/nuclai_report.pdf"

        }



        // Get the height of the gallery 
        const galleryHeight = window.innerHeight - 120;

        this.setState({
            canvasWidth,
            canvasHeight,
            dataIndex: 0,
            datasetPresignedURLArray,
            galleryHeight,
            isLoading: false,
            link
        })
    }

    // Handle the check to see if any annotations are available
    isAnnotationsAvailable = () => {

        // Loop over the fecthed files and return true if any annotations are found
        for (let fetchedFile in this.state.datasetPresignedURLArray) {
            if (this.state.datasetPresignedURLArray[fetchedFile].annotations['segmentation'].length > 0) {
                return false
            }
        }

        return true


    }

    isScaleZoomOutAvailable = () => {
        if (this.state.parentScaleFactor === 1) {
            return true
        } else {
            return false
        }
    }

    // Handle color change
    handleColorChange = (color, event) => {

        this.handleShowColorPicker(this.state.showColorPicker)

        this.setState({ shapeBorderColor: color.hex })
    }

    // Handle the download the PDF report 
    handleDownloadReport = () => {

    }

    handleNewAnnotation = (annotationType) => {
        // Check if the annotation type is zoom reset
        if (annotationType === 'center') {

            this.setState({
                newAnnotation: true,
                annotationType: annotationType,
                parentScaleFactor: "center"
            })

        } else {

            this.setState({
                newAnnotation: true,
                annotationType: annotationType
            })

        }
    }

    // Handle the shwoing of the color picker
    handleShowColorPicker = (status) => {
        this.setState({ showColorPicker: !status })
    }

    // Handle auto annotate task
    handleSubmitInferenceTask = async () => {

        // Set state to is loading
        this.setState({ isLoadingCanvas: true })

        // Take a timeout for 1 second
        await this.timer(1500);

        // Copy the state
        let newState = { ...this.state }

        // Update state with annotations
        // newState.datasetPresignedURLArray[this.state.dataIndex].annotations = this.state.annotations[newState.datasetPresignedURLArray[this.state.dataIndex]['fileName']]

        // Rescale the annotations
        newState.datasetPresignedURLArray[this.state.dataIndex].annotations = this.reformatAnnotationImageCanvasDimensions(this.state.annotations[newState.datasetPresignedURLArray[this.state.dataIndex]['fileName']])

        // Stop the loading
        newState.isLoadingCanvas = false

        // Switch the annotation mode to move
        newState.annotationType = 'move'

        // Save the state
        this.setState(newState)
    }

    // Handle the next or previous file
    handleSwitchDataFile = (currentIndex, status) => {

        if (currentIndex + status < 0) {
            return
        }

        if (currentIndex + status > this.state.datasetPresignedURLArray.length) {
            return
        }

        let newIndex = currentIndex += status

        this.setState({ dataIndex: newIndex })
    }

    // Change mode with keys
    onKeyDown = (keyName, e, handle) => {

        // Map the incoming keyboard shortcut
        let selectedAnnotatyionType = this.state.keyboardShortCuts[keyName]

        // Check if the chosen annotation type is any of the transforming types
        if (this.state.transformingAnnotationTypes.includes(selectedAnnotatyionType)) {

            // Then check if any annotations are available
            if (this.isAnnotationsAvailable()) {
                return
            }
        }

        // Call the annotation type change
        this.handleNewAnnotation(selectedAnnotatyionType)

    }
    // Reformat the annotations according to image canvas ratio dimensions
    reformatAnnotationImageCanvasDimensions = (annotationsObjects) => {

        let ratio = this.state.canvasWidth / 500

        let newFinalObject = {
            "segmentation": [],
            "objectDetection": []
        }

        let availableAnnotationTypes = ['objectDetection', 'segmentation']

        for (let annotationType in availableAnnotationTypes) {
            for (let annotation of annotationsObjects[availableAnnotationTypes[annotationType]]) {

                newFinalObject[availableAnnotationTypes[annotationType]].push({
                    "name": annotation['name'],
                    "region_area": Math.round(annotation['region_area']),
                    "coords": annotation['coords'].map(x => x / ratio),
                    "selected": false
                })

            }
        }

        return newFinalObject
    }

    // Timer for handling timeouts
    timer(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    // Handle the selection of a new file, take the input index and change the chosen data to that index
    updateFileSelection = (index) => {

        // Save the new chosen image
        this.setState({ dataIndex: index })

    }

    // Handle the update of the parent state
    updateParentState = (annotationObjects, annotationType, parentScaleFactor) => {

        // Copy the state
        let newState = Object.assign({}, this.state)

        if (typeof annotationObjects !== 'undefined') {
            newState.datasetPresignedURLArray[this.state.dataIndex].annotations[annotationType] = annotationObjects

        }

        newState.parentScaleFactor = parentScaleFactor

        this.setState(newState)
    }

    render() {
        return (
            <Grid >
                {/* Grid appbar start */}
                <Grid container className="controllPanelMarginTop">
                    <AppBar className="menuStyling controllPanelPosition">
                        <Toolbar>

                            {/* Previous file */}
                            <ToolTipFab
                                disableFab={this.state.isLoading || this.state.dataIndex === 0}
                                onClick={this.handleSwitchDataFile}
                                fabClassName={"demo__icon-button---margin-right "}
                                tooltipTitle={"Previous file"}
                                fabIcon={<ChevronLeftIcon />}
                                dataIndex={this.state.dataIndex}
                                switchStatus={-1}
                            />

                            {/* Next file */}
                            <ToolTipFab
                                disableFab={this.state.isLoading || this.state.dataIndex === this.state.datasetPresignedURLArray.length - 1}
                                onClick={this.handleSwitchDataFile}
                                fabClassName={"demo__icon-button---margin-between "}
                                tooltipTitle={"Next file"}
                                fabIcon={<ChevronRightIcon />}
                                dataIndex={this.state.dataIndex}
                                switchStatus={1}
                            />

                            <ToggleButtonGroup
                                className={"demo__toggleButtonGroup--margin-left desktop__display"}
                                value={this.state.annotationType}
                                exclusive
                                aria-label="text alignment"
                            >
                                <ToggleButton disabled={this.isScaleZoomOutAvailable()} onClick={() => this.handleNewAnnotation('zoomOut')} value="zoomOut" aria-label="zoomOut">
                                    <ZoomOutIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('zoomIn')} value="zoomIn" aria-label="zoomIn">
                                    <ZoomInIcon />
                                </ToggleButton>
                                <ToggleButton disabled={this.isScaleZoomOutAvailable()} onClick={() => this.handleNewAnnotation('center')} value="center" aria-label="center">
                                    <SettingsOverscanIcon />
                                </ToggleButton>

                            </ToggleButtonGroup>

                            <ToggleButtonGroup
                                className={"demo__toggleButtonGroup--margin-left desktop__display"}
                                value={this.state.annotationType}
                                exclusive
                                aria-label="text alignment"
                            >
                                <ToggleButton onClick={() => this.handleNewAnnotation('segmentation')} value="segmentation" aria-label="left aligned">
                                    <TimelineIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('objectDetection')} value="objectDetection" aria-label="centered">
                                    <CropFreeIcon />
                                </ToggleButton>

                            </ToggleButtonGroup>

                            <ToggleButtonGroup
                                className={"demo__toggleButtonGroup--margin-left desktop__display"}
                                value={this.state.annotationType}
                                exclusive
                                aria-label="text alignment"
                            >
                                <ToggleButton onClick={() => this.handleNewAnnotation('select')} value="select" aria-label="right aligned">
                                    <TouchAppIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('move')} value="move" aria-label="right aligned">
                                    <OpenWithIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('evaluate')} value="evaluate" aria-label="right aligned">
                                    <StraightenIcon />
                                </ToggleButton>

                            </ToggleButtonGroup>

                            <ToggleButtonGroup
                                className={"demo__toggleButtonGroup--margin-left desktop__display"}
                                value={this.state.annotationType}
                                exclusive
                                aria-label="text alignment"
                            >
                                <ToggleButton disabled={this.isAnnotationsAvailable()} onClick={() => this.handleNewAnnotation('add')} value="add" aria-label="right aligned">
                                    <AddCircleOutlineIcon />
                                </ToggleButton>
                                <ToggleButton disabled={this.isAnnotationsAvailable()} onClick={() => this.handleNewAnnotation('delete')} value="delete" aria-label="right aligned">
                                    <RemoveCircleOutlineIcon />
                                </ToggleButton>


                            </ToggleButtonGroup>

                            <ToggleButtonGroup
                                className={"demo__toggleButtonGroup--margin-left mobile__display"}
                                value={this.state.annotationType}
                                exclusive
                                aria-label="text alignment"
                            >
                                <ToggleButton disabled={this.isScaleZoomOutAvailable()} onClick={() => this.handleNewAnnotation('zoomOut')} value="zoomOut" aria-label="zoomOut">
                                    <ZoomOutIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('zoomIn')} value="zoomIn" aria-label="zoomIn">
                                    <ZoomInIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('move')} value="move" aria-label="right aligned">
                                    <OpenWithIcon />
                                </ToggleButton>
                                <ToggleButton onClick={() => this.handleNewAnnotation('evaluate')} value="evaluate" aria-label="right aligned">
                                    <StraightenIcon />
                                </ToggleButton>



                            </ToggleButtonGroup>

                            <div className="swatch demo__toggleButtonGroup--margin-left" onClick={() => this.handleShowColorPicker(this.state.showColorPicker)}>

                                <div className="color" style={{ 'background': this.state.shapeBorderColor }} />
                            </div>
                            {this.state.showColorPicker &&
                                <ColorPickerCustom
                                    color={this.state.shapeBorderColor}
                                    // disabledStatus={(this.state.editMode && value.editable === false)}
                                    // instructions={value.instructions}
                                    onChange={this.handleColorChange}
                                    onClick={this.handleShowColorPicker}
                                    showColorPicker={this.state.handleShowColorPicker}
                                    title={"hej"}
                                    value={this.state.shapeBorderColor}
                                />
                            }



                            {/* Button for auto annotation */}
                            <div className="desktop__display">

                                <CustomButton
                                    buttonClassName={"demo__toggleButtonGroup--margin-left"}
                                    buttonSize={"large"}
                                    disabledStatus={!this.state.isLoading || !this.state.isLoadingCanvas}
                                    fullWithStatus={false}
                                    isLoadingCanvas={this.state.isLoadingCanvas}
                                    onClick={this.handleSubmitInferenceTask}
                                    textLoading={"Analyzing.."}
                                    text={"AI analysis"}
                                />
                            </div>

                            {/* Button for downloading report */}
                            <div className="desktop__display">

                                <CustomButtonHttp
                                    buttonClassName={"demo__toggleButtonGroup--margin-left"}
                                    buttonSize={"large"}
                                    disabledStatus={!this.isAnnotationsAvailable()}
                                    fullWithStatus={false}
                                    href={this.state.link}
                                    textLoading={"Analyzing.."}
                                    text={"Download report"}
                                />
                            </div>



                        </Toolbar>

                        {/* Second toolbar for mobile use */}
                        <Toolbar className="mobile__display">



                            {/* Button for auto annotation */}
                            <div>

                                <CustomButton
                                    buttonClassName={"demo__toggleButtonGroup--margin-left"}
                                    buttonSize={"small"}
                                    disabledStatus={!this.state.isLoading || !this.state.isLoadingCanvas}
                                    fullWithStatus={false}
                                    isLoadingCanvas={this.state.isLoadingCanvas}
                                    onClick={this.handleSubmitInferenceTask}
                                    textLoading={"Analyzing.."}
                                    text={"AI analysis"}
                                />
                            </div>

                            {/* Button for downloading report */}
                            <div>

                                <CustomButtonHttp
                                    buttonClassName={"demo__toggleButtonGroup--margin-left"}
                                    buttonSize={"small"}
                                    disabledStatus={!this.isAnnotationsAvailable()}
                                    fullWithStatus={false}
                                    href={this.state.link}
                                    textLoading={"Analyzing.."}
                                    text={"Download report"}
                                />
                            </div>



                        </Toolbar>

                    </AppBar>
                </Grid>
                {/* Grid appbar end */}

                {/* Grid studio child start */}
                <Grid container>

                    {this.state.isLoading
                        ?
                        <LoadingStatus
                            className={"loadingGifMargin"}
                            loaderImage={require("../images/loader_gif.gif")}
                            loaderImageHeight={150}
                        />
                        :
                        <Fragment>
                            <Grid container justify="space-around">

                                <Hotkeys
                                    keyName="p,r,c,m,s,a,d,z,-,e"
                                    onKeyDown={this.onKeyDown}
                                    onKeyUp={this.onKeyUp}
                                >

                                    <AnnotationStudio
                                        annotations={this.state.datasetPresignedURLArray[this.state.dataIndex].annotations}
                                        annotationType={this.state.annotationType}
                                        canvasWidth={500}
                                        canvasHeight={this.state.canvasHeight / (this.state.canvasWidth / 500)}
                                        galleryHeight={this.state.galleryHeight}
                                        handleFileSelection={this.handleFileSelection}
                                        handleSaveAnnotation={this.handleSaveAnnotation}
                                        datasetPresignedURLArray={this.state.datasetPresignedURLArray}
                                        dataIndex={this.state.dataIndex}
                                        isLoadingCanvas={this.state.isLoadingCanvas}
                                        parentScaleFactor={this.state.parentScaleFactor}
                                        segmentation={this.state.segmentation}
                                        shapeBorderColor={this.state.shapeBorderColor}
                                        objectDetection={this.state.objectDetection}
                                        updateFileSelection={this.updateFileSelection}
                                        updateParentState={this.updateParentState}

                                    />

                                </Hotkeys>
                                {/* <p>{JSON.stringify(this.state)}</p> */}


                            </Grid>
                            {JSON.stringify(this.state.link)}

                        </Fragment>

                    }

                </Grid>
                {/* Grid studio child end */}

            </Grid>


        )
    }
}
export default Demo